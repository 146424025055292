import * as Utilities from "./utilities";

class FlowboxClass {
    private static _instance: FlowboxClass;

    public constructor() {
        const initObj: any = {
            container: "#js-flowbox-flow",
            key: Utilities.DataListObj.FlowboxKey,
            locale: Utilities.DataListObj.LangCode
        };

        (window as any).flowbox("init", initObj);
    }

    public static get Instance() {
        if (!(window as any).flowbox)
            return;

        if (!document.querySelector("#js-flowbox-flow")) 
            return;

        return this._instance || (this._instance = new this());
    }
}

export let clsFlowbox = (window as any).clsFlowbox;

window.addEventListener("load", (event) => {
    let e = event;

    if (!clsFlowbox) {
        clsFlowbox = FlowboxClass.Instance;

        if (clsFlowbox) {
            (window as any).clsFlowbox = clsFlowbox;
        }
    }
});