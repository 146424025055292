import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { modal } from "./modal";
declare function Swiper(...args: any[]): void;

class Home {
    private static _instance: Home;
    private static _context: HTMLElement = document.getElementById("home");
    private _swipers: Array<any> = [];
    private _mobileSwipers: Array<any> = [];

    private constructor() {
        Home._context.querySelectorAll(".swiper-container.swiper-all").forEach(swiper => {
            this._swipers.push(new (Swiper as any)(swiper, this.GetSwiperConfiguration(swiper as HTMLElement)));
        });

        const link = Home._context.querySelector(".link-checkdeliverydate");
        if (link)
            modal.Show("modal-checkdeliverydate");

        const screen_Sm_Lower = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.lower);
        screen_Sm_Lower.addListener((e) => this.Screen_Sm_Lower_Change(e.matches));
        this.Screen_Sm_Lower_Change(screen_Sm_Lower.matches);

        const groupProd = Home._context.querySelector(".home-groupprod");
        if (groupProd) {
            groupProd.querySelectorAll(".swiper-slide").forEach((slide: HTMLElement) => {
                slide.addEventListener("click", () => (window as any).swogoGA.AddToCart({ productReference: slide.dataset.productReference, itemReference: slide.dataset.itemReference, quantity: 1 }));
            });
            groupProd.querySelector(".group .btn").addEventListener("click", () => {
                groupProd.querySelectorAll(".swiper-slide").forEach((slide: HTMLElement) => {
                    (window as any).swogoGA.AddToCart({ productReference: slide.dataset.productReference, itemReference: slide.dataset.itemReference, quantity: 1 });
                });
            });
        }
    }

    private Screen_Sm_Lower_Change(matches: boolean) {
        if (matches) {
            while (this._mobileSwipers.length > 0)
                this._mobileSwipers.shift().destroy();
        } else {
            if (typeof Swiper === "function") {
                Home._context.querySelectorAll(".swiper-container.swiper-mobile").forEach(swiper => {
                    this._mobileSwipers.push(new (Swiper as any)(swiper, this.GetSwiperConfiguration(swiper as HTMLElement)));
                });
            }
        }
    }

    private GetSwiperConfiguration(swiper: HTMLElement) {
        return {
            spaceBetween: 15,
            preloadImages: false,
            watchOverflow: true,
            lazy: true,
            navigation: {
                nextEl: swiper.querySelector(".swiper-button-next"),
                prevEl: swiper.querySelector(".swiper-button-prev"),
            },
            pagination: {
                el: swiper.querySelector(".swiper-pagination"),
            },
            autoplay: {
                delay: 7000,
            },
        };
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}
export const home = Home.Instance;