import { gtmImpressionPromotion } from "./cs.gtm";

$(document).ready(function () {
    let $flexslider = $(".flexslider");
    $flexslider.flexslider({
        animation: "slide",
        manualControls: ".flex-control-nav li",
        useCSS: false /* Chrome fix*/,
        pausePlay: true,               //Boolean: Create pause/play dynamic element
        pauseText: "",             //String: Set the text for the "pause" pausePlay item
        playText: "",
        directionNav: true,
        customDirectionNav: $(".custom-navigation a"),
        before: function (slider) {
            if (slider.isInViewport())
                gtmImpressionPromotion($(slider.slides[slider.currentSlide]).find("a")[0]);
        },
        start: function (slider) {
            slider.find(".flexslider-controls").toggle(slider.find(".slides > li:not('.clone')").length > 1);
        }
    });

    let mobileProducts1 = $("#mobile-products1");
    if (mobileProducts1.owlCarousel) {
        mobileProducts1.owlCarousel({
            itemsCustom: [
                [0, 1.5],
                [450, 2.5]
            ],
            navigation: false
        });

        let mobileProducts2 = $("#mobile-products2");
        mobileProducts2.owlCarousel({
            itemsCustom: [
                [0, 1.5],
                [450, 2.5]
            ],
            navigation: false
        });

        let mobileProducts3 = $("#mobile-products3");
        mobileProducts3.owlCarousel({
            itemsCustom: [
                [0, 1.5],
                [450, 2.5]
            ],
            navigation: false
        });

        let mobileProducts4 = $("#mobile-products4");
        mobileProducts4.owlCarousel({
            itemsCustom: [
                [0, 1.5],
                [450, 2.5]
            ],
            navigation: false
        });

        let mobileProducts5 = $("#mobile-products5");
        mobileProducts5.owlCarousel({
            itemsCustom: [
                [0, 1.5],
                [450, 2.5]
            ],
            navigation: false
        });
    }

    /* Flex Slider Scrolling */
    $("#scrollFlex").bind("mousedown", function (event) {
        scrolling = true;
        if ($("#scrollFlex").hasClass("next")) {
            scrollContent("left");
        }
        else {
            scrollContent("right");
        }

    }).bind("mouseup", function (event) {
        scrolling = false;
    });

    /* Flex scroll button visibility */
    if ($(".flex-control-nav").width() > $(".flex-control-nav-wrapper").width()) {
        $(".flexslider-controls .flex-direction-nav").show();
    }
    else {
        $(".flexslider-controls .flex-direction-nav").hide();
    }
});

function scrollContent(direction) {
    let amount = (direction === "left" ? "+=3px" : "-=3px");
    $("#flexContentToScroll").animate({
        scrollLeft: amount
    }, 1, function () {
        if ($("#flexContentToScroll").scrollLeft() == ($(".flex-control-nav").width() - $("#flexContentToScroll").width())) {
            $("#scrollFlex").removeClass("next").addClass("previous");
            $("#scrollFlex").find("span.glyphicon").removeClass("glyphicon-menu-right").addClass("glyphicon-menu-left");
            rightReached = true;
        }
        if ($("#flexContentToScroll").scrollLeft() == 0) {
            $("#scrollFlex").removeClass("previous").addClass("next");
            $("#scrollFlex").find("span.glyphicon").removeClass("glyphicon-menu-left").addClass("glyphicon-menu-right");
            rightReached = false;
        }
        if (scrolling) {
            scrollContent(direction);
        }
    });
}